.table-remark {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
}

.title {
    padding: 3px 8px;
    background: #eceafa;
    border-radius: 4px;
    font-weight: 400;
    font-size: 15px;
    margin-right: 10px;
    width: 70px;
    text-align: center;
  }

  .computed-title {
    padding: 3px 8px;
    font-weight: 400;
    font-size: 15px;
    margin-right: 10px;
    width: 75px;
    text-align: center;
    border-left: 4px solid #C5C0E0; ;
  }

.flex-row {
    margin-bottom: 20px;
}