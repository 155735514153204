@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

@import "./common/style/dataForm.scss";
@import "./common/style/dataTable.scss";
.item-date {
  text-align: left;
}
.row3 {
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
}
/* .row2-card {
  overflow: hidden !important;
} */
